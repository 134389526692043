// Treadmill styling
 
.treadmill {
  path, polygon {
    transition: .3s ease-out;
    fill: lighten(@color-treadmill, 15%);
    stroke: lighten(@color-treadmill, 0%);
    stroke-width: 1px;
  }
}

.heart-beat {
  fill: none;
  stroke-dasharray: 300;
  stroke-dashoffset: 300;

  &.slow {
    stroke: green;
    animation: dash 2s linear forwards;
    animation-iteration-count: 10;
  }

  &.fast {
    stroke: red;
    animation: dash 0.8s linear forwards;
    animation-iteration-count: 10;
  }
  
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

.slide-view.is-active {
  &[data-step="0"] {
  }

  &[data-step="1"] {
  }

  &[data-step="2"] {
  }

  &[data-step="3"] {
  }

  &[data-step="4"] {
  }
}
