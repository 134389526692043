@import "colors";
@import "roboto";

.compare-tutorial {
  b {
    font-weight: 500;
  }

  .is-highlighted {
    background-color: lighten(@color-highlight, 10%);

    .swoop-background {
      fill: lighten(@color-highlight, 10%) !important;
      stroke: darken(@color-highlight, 20%) !important;
    }
    .swoop-path,
    .swoop-arrow {
      stroke: darken(@color-highlight, 20%) !important; 
    }
    text { fill: darken(@color-highlight, 40%); }
  }

  .toggle-fullscreen {
    border: none;
  }

  .publication-header-container.affix {
    border-bottom: solid 20px white;
  }

  a[data-toggle="collapse"] {
    cursor: pointer;
  }
  .tutorial-details {
    font-size: 15px !important;
  }

  .footer-container {
    position: relative;
    z-index: 1000;

    hr {
      display: none;
    }

    footer {
      background-color: white;
      background-color: rgba(255, 255, 255, 0.6);
      border-top: solid 1px #eee;
      margin-top: 20px;
      padding-top: 20px;
    }
  }

  .js-go-to-index,
  .js-highlight {
    white-space: nowrap;
    padding: 0.1em 0.2em;
    color: #333;
    border-bottom: dashed 1px darken(@color-highlight, 10%);
    border-radius: 0;
    background-color: lighten(@color-highlight, 15%);

    &:hover,
    &:focus,
    &:active {
      border-bottom-style: solid;
      text-decoration: none;
    }

    &.is-highlighted {
      background-color: @color-highlight;
    }
  }

  .tutorial-start {
    clear: both;
    margin-top: 20px;
    text-align: center;
    opacity: 1;
    transition: opacity 0.5s;

    a { font-size: 16px; padding: 0.6em 1em; }
    &.is-started {
      opacity: 0;
    }
  }

  .tutorial-header-controls {
    line-height: 42px;
    opacity: 0;
    transition: opacity 0.3s;

    &.is-attached {
      opacity: 1;
    }
    .js-start-tutorial.is-started {
      opacity: 0;
    }
  }

  footer {
    padding-bottom: 20px;
  }

  code {
    color: black;
  }

  .no-padding {
    padding: 0;
  }
}

// Components
@import "slides-view";
@import "slide-view";
@import "progress";
@import "equation";
@import "charts";
@import "treadmill";
