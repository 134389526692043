@import "colors";

@clir-dasharray: none;
@bg-dasharray: 4 4 4;
@gap_dasharray: 5 2;

//
// d3.compose overrides
//

.mclir-tutorial-chart {
  .chart-compose {
    border: none;
  }

  .chart-axis .tick line,
  .chart-axis path {
    stroke: @color-axis;
    stroke-width: 0.8pt;
  }

  .chart-axis text {
    fill: @color-axis;
  }

  .chart-title text {
    font-size: 0.7em;
  }

  .chart-legend-label {
    font-size: 0.75em; 
  }

  .chart-subtitle {
    font-size: 0.6em;
  }

  .chart-axis-title {
    fill: @color-axis;
  }
  //
  // hero-caption
  //

  .chart-hero-caption text {
    font-size: 50pt;
    fill: lighten(black, 95%);
  }

  //
  // Series styling
  //

  .bg_rate {
    fill: @color-bg;
    stroke: @color-bg;
    stroke-dasharray: @bg-dasharray;
    stroke-linecap: round;

    &.gap {
      color: @color-bg-text;
    }
    &.point {
      stroke-dasharray: none;
    }
  }

  .mclir,
  .perfect_rate {
    color: @color-perfect;
    fill: @color-perfect;
    stroke: @color-perfect;

    &.gap {}
    &.point {}
  }
  .mclir {
    stroke-dasharray: @clir-dasharray;
  }

  .rclir,
  .realistic_rate {
    color: @color-realistic;
    fill: @color-realistic;
    stroke: @color-realistic;

    &.gap {}
    &.point {}
  }
  .rclir {
    stroke-dasharray: @clir-dasharray;
  }

  .reduction {
    color: @color-reduction-text;
    fill: @color-reduction; 
    stroke: @color-reduction;

    &.gap {
      circle {
        r: 5; 
      }
    }
  }

  .full {
    opacity: 0.20;
  }

  //
  // chart-swoops
  //

  .chart-swoops .swoop-arrow {
    fill: none;
    stroke: @color-swoop;
    stroke-width: 1.5px;
  }
  .chart-swoops .swoop-path {
    fill: none;
    stroke: @color-swoop;
    stroke-width: 1.5px;
  }
  .chart-swoops text {
    stroke-width: 0px;
    font-size: 0.8em;
    text-anchor: middle;
    fill: darken(@color-swoop, 15%);
  }
  .chart-swoops .swoop-background {
    fill: #FEFEFE;
    stroke: #999;
  }

  .swoop-labels-rclir text,
  .swoop-labels-realistic-rate text {
    fill: @color-realistic;
  }

  //
  // table-labels
  //

  @table-list-offset: 12px;

  .table-labels {
    position: relative;
    transition: transform 0.25s;

    .no-transition & {
      transition: none;  
    }

    &.is-inverted {
      .table-labels-arrow {
        left: auto;
        right: 0;
      }
      .table-labels-list {
        left: auto;
        right: @table-list-offset;
      }
    }
  }
  .table-labels-arrow {
    position: absolute;
    top: -8px;
    left: 0;
    background-color: transparent;

    path {
      fill: white;
      stroke: #ccc;
    }
  }
  .table-labels-list {
    position: absolute;
    left: @table-list-offset;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    margin: 0;
    padding: 2px 0 5px 0;
    list-style: none;
    background-color: white;
    box-shadow: 0px 0px 5px #aaa;

    li {
      margin: 0px 4px;
      padding: 0;
      font-size: 12px !important;
      white-space: nowrap;
      line-height: 70%;

      .is-fullscreen & {
        font-size: 14px !important;
      }
      @media screen and (max-width: 1000px) {
        &,
        .is-fullscreen & {
          font-size: 10px !important;
        }
      }
    }
  }
  .table-labels-header {
    display: inline-block;
    margin-top: 4px;
    font-size: 110%;
    font-weight: bold;
  }
  .table-labels-swatch {
    display: inline-block;
    position: relative;
    top: 5px;
    margin-right: 6px;
  }

  //
  // Curly brackets 
  //

  .chart-curly-path {
    stroke: @color-reduction;
    fill: none;
  }

  //
  // Model-specific styling
  //
  .model-b {
    stroke: orange;
    fill: orange;
  }
  .model-c {
    stroke: lighten(black, 50%);
    fill: lighten(black, 50%);
  }
  .model-c .table-labels-label {
    color: lighten(black, 50%);
  }
  .model-b .table-labels-label {
    color: orange;
  }

  // 
  // Area
  //

  .chart-area {
    .chart-index-0 {
      fill: #eee;
      stroke: none;
    }
  }

  //
  // Formatted Title
  //

  .chart-formatted-title {
    .format-superscript {
      // Appears to be broken, but changed to sub anyway
      baseline-shift: sub;
      font-size: 70%;
    }
  }
}
