.slide-view {
  position: relative;
  padding: 0;

  p {
    line-height: 170%;
  }

  .col-main,
  .col-aside {
    float: left;
    min-height: 1px;
    position: relative;
  }

  .col-main {
    width: 55%;
    padding-left: 15px;
    padding-right: 15px;
  }
  .col-aside {
    width: 45%;
    .table-title {
      display: block;
      line-height: 1.3em;
      font-size: 11pt;
      margin-bottom: 6px;
    }
    .dwell-table {
      th {
        padding: 0px;
      }
    }
  }
  
  .col-main.small {
    padding-right: 80px;
  }

  & > h2:first-child {
    margin-top: 5px;
  }

  h1, h2, h3 {
    font-weight: 400;
  }

  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.2em;
  }

  .muted {
    color: #bbbbbb;
  }

  .tutorial-chart-aligned {
    padding-left: 26%;
    padding-right: 3%;
  }

  .slide-slider {
    margin: 16px 18px 0 auto;
    width: 75%;
  }

  .restart-tutorial {
    text-align: center;
    margin-top: 2em;
  }
  ol.dwell-highlight li{
    margin-top: 5px;
    line-height: 160%;
  }
}
