@nav-point-size: 16px;

.tutorial-progress-container {
  position: relative;
  height: @nav-point-size;
  line-height: @nav-point-size;
}
.tutorial-progress {
  position: absolute;
  background-color: @color-progress-light;
  top: @nav-point-size / 2 - 2px;
  width: 100%;
  height: 4px;
}
.tutorial-progress-indicator {
  background-color: @color-progress-highlight;
  height: 4px;
  width: 0%;
}
.tutorial-progress-points {
  position: relative;
  list-style: none;
  margin: 0;
  padding: 0;
}
.tutorial-progress-point {
  position: absolute;
  margin: 0;
  padding: 0;
  transform: translateX(-50%);

  a {
    &:focus {
      outline: none;
      display: inline-block;
      span {
        box-shadow: 0 0 2px 2px @color-progress-highlight;
      }
    }
  }

  span {
    display: inline-block;
    background-color: white;
    border: solid 2px @color-progress-light;

    width: @nav-point-size;
    height: @nav-point-size;
    border-radius: @nav-point-size/2;
  }

  .tutorial-progress:hover & span {
    border-color: darken(@color-progress-light, 10%);
  }

  &.is-active,
  .tutorial-progress:hover &.is-active {
    span {
      border-color: @color-progress-highlight;
      background-color: @color-progress-highlight;
    }
  }

  &.is-current,
  .tutorial-progress:hover &.is-current {
    span {
      border-color: @color-progress-highlight;
      background-color: white;
      box-shadow: 0 0 2px 2px @color-progress-highlight;
    }
  }

  a:hover span,
  &.is-active a:hover span {
    background-color: white;
    box-shadow: 0 0 2px 2px @color-progress-highlight;
  }
}
