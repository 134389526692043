@import "colors";

.equation-view {
  font-size: 13px;
  text-align: center;
  margin-bottom: 10px;

  .large {
    font-size: 130%;
  }

  .large.sixty-two:after {
    content: '62';
    font-size: .6em;
    line-height: 0.7em;
    vertical-align: baseline;
    position: relative;
    top: 0em;
  }
}

.equation-table {
  font-size: 16px;
  width: 100%;
  max-width: 34em;

  .equation-lhs {
    width: 5em;
    text-align: right;
  }
  .equation-eq {
    width: 5em;
    text-align: center;
  }
  .equation-rhs {
    text-align: center;
  }
}

.fraction {
  display: inline-block;
  vertical-align: middle; 
  margin: 0 0.2em;
  text-align: center;
}
.fraction > span {
  display: block;
  padding-top: 0.15em;
}
.fraction .fdn {
  border-top: thin solid black;
}
.fraction .bar {
  display: none;
}

@media screen and (max-width: 1140px) {
  .equation {
    font-size: 11px;
  }
}
