@import "colors";

@slide-width: 55%;
@chart-width: 98% - @slide-width;
@slide-font: Roboto, Arial, sans-serif;

.slides-view {
  min-height: calc(~"100vh - 40px");
  margin-right: -15px;

  .slide-btn {
    &[hidden] {
      display: none;
    }

    .icon-component {
      padding-top: 0;
      height: 0.8em;
      vertical-align: text-top;
    }
  }
}

.tutorial-slides {
  position: relative;
  overflow: hidden;

  .tab-pane {
    display: block;
    visibility: hidden;
    position: absolute;
    top: 0;

    // Prevents strange issue of treadmill svg not being properly hidden
    .treadmill { display: none; }

    &.is-active {
      visibility: visible;
      position: relative;

      .treadmill { display: block; }
    }

    &.transition-slide {
      transform: translateX(700px);
      transition: transform 0.5s;
      transition-timing-function: ease;

      &.from-left {
        transform: translateX(-700px);
      }

      &.is-active {
        transform: translateX(0);
      }
    }
  }
}

.tutorial-footer {
  display: flex;
  height: 50px;
  line-height: 50px;
  margin-top: 20px;
  border-top: solid 1px @color-lighter-grey;
  opacity: 0;
  transition: opacity 0.5s;

  // Don't show footer until it's positioned
  &.is-visible { opacity: 1; }
}

.tutorial-footer-leading {
  margin-right: 30px;
}

.tutorial-footer-progress {
  flex: 1;
  margin-top: 18px;
}

.tutorial-footer-page {
  margin-left: 20px;
}

.tutorial-footer-trailing {
  margin-left: 10px;
}

// General / Component Styles
// --------------------------

// Slider

.tutorial-slider-container {
  margin-bottom: 0.5em;
  padding-top: 4px;

  label {
    display: block;
    font-weight: normal;
    text-align: center;
  }
  .tutorial-slider-label {
    display: none;
    font-size: 14px;
    color: @color-grey;
    margin-bottom: 4px;
    transition: opacity 1s;

    .icon-component {
      fill: @color-light-grey;
      position: relative;
      top: 4px;
    }
  }
  
  .slider.slider-horizontal {
    width: 100%;
  }
  .slider-tick-label {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }
  .slider-handle:hover {
    cursor: pointer;
  }

  &.mod-has-interacted {
    .tutorial-slider-label {
      opacity: 0;
    }
  }

  &.mod-62-tick,
  &.mod-62-70-tick,
  &.mod-65-70-tick {
    .slider-tick-label-container {
      margin-left: 0 !important;
    }
    .slider-tick-label {
      transform: translateX(-50%);
    }
  }

  &.mod-62-tick {
    .slider-tick-label:nth-child(1) {
      width: 24% !important;
    }
    .slider-tick-label:nth-child(2) {
      width: 76% !important;
    }
  }

  &.mod-62-70-tick {
    .slider-tick-label:nth-child(1) {
      width: 21% !important;
    }
    .slider-tick-label:nth-child(2) {
      width: 79% !important;
    }
  }
  &.mod-65-70-tick {
    .slider-tick-label:nth-child(1) {
      width: 14.5% !important;
    }
    .slider-tick-label:nth-child(2) {
      width: 85.5% !important;
    }
  }
}

// Tooltip

.tutorial-tooltip {
  pointer-events: none;

  &.in {
    opacity: 1;
  }

  &.bottom .tooltip-arrow {
    border-bottom-color: @color-progress-highlight;
  }
  &.top .tooltip-arrow {
    border-top-color: @color-progress-highlight;
  }
  .tooltip-inner {
    white-space: pre;
    font-size: 14px;
    background-color: @color-progress-highlight;
    max-width: none;
  }
}

// Highlight tooltip

.highlight-tooltip {
  pointer-events: none;

  &.in {
    opacity: 1;
  }

  .highlight-tooltip-container {
    transform: translateX(50%);
    white-space: nowrap;
  }

  .highlight-tooltip-arrow {
    display: inline-block;
    position: relative;
    top: 10px;
    left: -10px;
    z-index: 20;
    vertical-align: bottom;

    svg {
      width: 40px;
      height: 30px;
      vertical-align: bottom;
    }
    path {
      stroke: @color-highlight-tooltip;
      stroke-width: 3px;
      fill: none;
    }
    .ht-arrow-path {
      transform: translate(40px, 5px);
    }
    .ht-arrow-head {
      transform: translate(10px, 26px) rotate(12deg);
    }
  }

  .tooltip-inner {
    display: inline-block;
    position: relative;
    left: -11px;
    z-index: 10;
    white-space: nowrap;
    vertical-align: top;
    padding: 3px 0.75em;
    line-height: 24px;
    font-size: 14px;
    color: black;
    background-color: lighten(@color-highlight-tooltip, 40%);
    border-left: solid 3px @color-highlight-tooltip;
    max-width: none;
    text-align: left;
  }

  &.top {
    padding: 0 0 5px 0;
  }

  &.bottom {
    padding: 0;

    .highlight-tooltip-arrow {
      top: 0;

      svg {
        vertical-align: top;
      }
      .ht-arrow-path {
        transform: translate(10px, 5px);
      }
      .ht-arrow-head {
        transform: translate(10px, 5px) rotate(168deg);
      }
    }
  }
}
